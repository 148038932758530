import { auth } from "aws-iot-device-sdk-v2";
import {
  CognitoIdentityCredentials,
  fromCognitoIdentityPool,
} from "@aws-sdk/credential-provider-cognito-identity";
import { AuthSession } from "aws-amplify/auth";

interface AWSCognitoCredentialOptions {
    IdentityPoolId: string;
    Region: string;
    Session: AuthSession;
  }
  
export class AWSCognitoCredentialsProvider extends auth.CredentialsProvider {
    private options: AWSCognitoCredentialOptions;
    private cachedCredentials?: CognitoIdentityCredentials;
  
    constructor(
      options: AWSCognitoCredentialOptions,
      expire_interval_in_ms?: number
    ) {
      super();
      this.options = options;
  
      setInterval(async () => {
        await this.refreshCredentials();
      }, expire_interval_in_ms ?? 3000 * 1000);
    }
  
    getCredentials() {
      return {
        aws_access_id: this.cachedCredentials?.accessKeyId ?? "",
        aws_secret_key: this.cachedCredentials?.secretAccessKey ?? "",
        aws_sts_token: this.cachedCredentials?.sessionToken,
        aws_region: this.options.Region,
      };
    }
  
    async refreshCredentials() {
      this.cachedCredentials = await fromCognitoIdentityPool({
        // Required. The unique identifier for the identity pool from which an identity should be
        // retrieved or generated.
        identityPoolId: this.options.IdentityPoolId,
        clientConfig: { region: this.options.Region },
        logins: {
          "cognito-idp.us-west-2.amazonaws.com/us-west-2_VkrAHtBGR":
            this.options.Session.tokens?.idToken?.toString() ?? "",
        },
      })();
    }
  }