export const TIME_SUFFIX = 'T12:00:00Z'

export function formatTime(time: string | undefined) {
  if (!time) {
    return '';
  }

  let baseTime = time.split('T')[1].slice(0, 5);
  let [hours, mins] = baseTime.split(':');

  const hoursNumber = Number(hours);
  if (hoursNumber === 0 || hoursNumber === 12) {
    return `12:${mins} ${hoursNumber === 0 ? 'AM': 'PM'}`;
  }

  if (hoursNumber < 12) {
    return `${hoursNumber}:${mins} AM`;
  }

  return `${hoursNumber % 12}:${mins} PM`;
}

export function dateToString(date: Date | undefined | null) {
  return date ? `${date.getUTCFullYear()}-${('0' + (date.getUTCMonth() + 1)).slice(-2)}-${(
    '0' + date.getUTCDate()
  ).slice(-2)}` : '';
}