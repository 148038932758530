import { once } from "events";
import { mqtt5, iot } from "aws-iot-device-sdk-v2";
import { AWSCognitoCredentialsProvider } from "./CredentialsProvider";

const region = 'us-west-2'

const TOPIC = process.env.REACT_APP_ENVIRONMENT + '/board'

export const createClient = (provider: AWSCognitoCredentialsProvider, onMessageHandler?: (message: mqtt5.Payload | undefined) => void): mqtt5.Mqtt5Client => {
    let wsConfig: iot.WebsocketSigv4Config = {
      credentialsProvider: provider,
      region,
    };

    let builder: iot.AwsIotMqtt5ClientConfigBuilder =
      iot.AwsIotMqtt5ClientConfigBuilder.newWebsocketMqttBuilderWithSigv4Auth(
        "a65waf1rxvw08-ats.iot.us-west-2.amazonaws.com",
        wsConfig
      );

    let client: mqtt5.Mqtt5Client = new mqtt5.Mqtt5Client(builder.build());

    client.on("error", (error) => {
      // console.log("Error event: " + error.toString());
    });

    client.on(
      "connectionFailure",
      (eventData: mqtt5.ConnectionFailureEvent) => {
        // console.log("Connection failure event: " + eventData.error.toString());
      }
    );

    client.on("disconnection", (eventData: mqtt5.DisconnectionEvent) => {
      // console.log("Disconnection event: " + eventData.error.toString());
      if (eventData.disconnect !== undefined) {
        console.log(
          "Disconnect packet: " + JSON.stringify(eventData.disconnect)
        );
      }
      
      connect(client)
    });

    client.on("stopped", (eventData: mqtt5.StoppedEvent) => {
      // console.log("Stopped event");
    });

    return client
}

export const connect = async (client: mqtt5.Mqtt5Client) => {
    const attemptingConnect = once(client, "attemptingConnect");
    const connectionSuccess = once(client, "connectionSuccess");

    client.start();

    await attemptingConnect;
    await connectionSuccess;

    const suback = await client.subscribe({
        subscriptions: [{ qos: mqtt5.QoS.AtMostOnce, topicFilter: TOPIC }],
      });
      // console.log("Suback result: " + JSON.stringify(suback));
      // console.log(suback);
}

export const publish = async (client: mqtt5.Mqtt5Client, payload: string) => {
    const qos0PublishResult = await client.publish({
        qos: mqtt5.QoS.AtMostOnce,
        topicName: TOPIC,
        payload,
      });
      // console.log("publish result: " + JSON.stringify(qos0PublishResult));
}



  