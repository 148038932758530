import { ChangeEvent, useState } from "react";

import { MenuItem, TextField } from "@mui/material";

function FullView() {
  const [horse, setHorse] = useState<string>("");

  return (
    <TextField
      label="Horse"
      select
      required
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        setHorse(e.target.value);
      }}
      value={horse}
      name="horse"
      fullWidth
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
    </TextField>
  );
}

export default FullView;
