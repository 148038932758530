import { Grid } from "@mui/material";
import { useState } from "react";
import FullView from "../components/FullView";
import DayView from "../components/DayView";

function Home() {
  const [mode, setMode] = useState<"full" | "day">("day");

  return (
    <Grid container>
      <Grid item xs={12}>
        {mode === "full" ? <FullView /> : <DayView />}
      </Grid>
    </Grid>
  );
}

export default Home;
