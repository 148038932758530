import "./App.css";

import { Authenticator } from "@aws-amplify/ui-react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Root } from "./pages/Root";
import Home from "./pages/Home";
import { Login } from "./pages/Login";
import { RequireAuth } from "./components/RequireAuth";
import { WebsocketProvider } from "./providers/WebsocketProvider";

const router = createBrowserRouter([
  {
    element: <Root />,
    errorElement: <div>Not Found</div>,
    children: [
      {
        path: "/",
        element: (
          <RequireAuth>
            <Home />
          </RequireAuth>
        ),
      },
      {
        path: "/login",
        element: <Login />,
      },
    ],
  },
]);

// A comment - oh no!!
function App() {
  return (
    <Authenticator.Provider>
      <WebsocketProvider>
        <RouterProvider router={router} />
      </WebsocketProvider>
    </Authenticator.Provider>
  );
}

export default App;
